// import React, { useContext, useEffect, useState } from "react";
// import SelectField from "../../../../components/select/selectField";
// import { Box, Button, Card, Grid } from "@mui/material";
// import { datePayload, getSelectedDate } from "../../../../utils/moduleTabApi";
// import RangeSelector from "../../../../components/rangeSelector";
// import { GlobalData } from "../../../../context/globalData";
// import IssueTypeAllocationTrend from "../../../workAllocation/IssueTypeAllocationTrend";
// import { style } from "../style";
// import instance from "../../../../utils/axiosHelper";
// import RelevanceLabSprintTrend from "../../../Sprint/Detail/RelevanceLab/trendRelevance";
// import IssueLevel from "../IssueLevel";
// import NormalSelect from "../../../../components/select/normalSelect";

import { useContext, useEffect, useState } from "react";
import { style } from "../style";
import { GlobalData } from "../../../../context/globalData";
import { datePayload, getSelectedDate } from "../../../../utils/moduleTabApi";
import instance from "../../../../utils/axiosHelper";
import SelectField from "../../../../components/select/selectField";
import { Box, Button, Card, Grid } from "@mui/material";
import RangeSelector from "../../../../components/rangeSelector";
import NormalSelect from "../../../../components/select/normalSelect";
import IssueTypeAllocationTrend from "../../../workAllocation/IssueTypeAllocationTrend";
import RelevanceLabSprintTrend from "../trend/RelevenceTrend";
import IssueLevel from "../IssueLevel";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

const useStyles = style;
const CodeHealth = ({ projectId }) => {
  const classes = useStyles();
  const { setIsDefinaionPopup, setDefinationKPI, projectRangePickerValue } =
    useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [openRange, setOpenRange] = useState(false);
  const [SelectedMetricName, setSelectedMetricName] = useState("");
  const [sonarQIssue, setSonarQIssue] = useState();
  const [sonarQMetric, setSonarQMetric] = useState({});

  const getMetricName = (name) => {
    setSelectedMetricName(name);
  };

  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/sonarQubeMetric/${projectId}?${dateUrl}`;
    if (sonarQIssue) {
      url += `&filter=${sonarQIssue}`;
    }
    instance
      .get(url)
      .then((response) => {
        console.log(response?.data);
        const graphData = response.data.map((name) => {
          return {
            country: name?.metric,
            litres: name?.total,
            additionalInfo: `${name?.metric}:${name?.total} Count`,
          };
        });

        const getTotalCount = response?.data?.reduce(
          (acc, curr) => acc + curr.total,
          0
        );
        setSonarQMetric({ data: graphData, total: getTotalCount });
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, sonarQIssue]);

  return (
    <div>
      <div className="d-flex">
        <div style={{ marginTop: -8 }}>
          <SelectField
            input={
              <Button
                onClick={() => setOpenRange(true)}
                style={{
                  border: "1px solid #b7b7b7",
                  width: "230px",
                  textTransform: "capitalize",
                  padding: "5px",
                  fontSize: "14px",
                  fontWeight: 400,
                  paddingLeft: "14px ",
                  paddingRight: "11px",
                  justifyContent: "space-between",
                  borderRadius: "5px",
                  height: "54px",
                  color: "black",
                }}
                endIcon={<DateRangeOutlinedIcon />}
              >
                {getSelectedDate(date)}
              </Button>
            }
            onClick={() => setOpenRange(true)}
          />
          <RangeSelector
            open={openRange}
            setOpen={setOpenRange}
            pickerHandler={(val) => setDate(val)}
            value={date}
            isNextDate={false}
          />
        </div>
        {/* <SelectField
          render={["Critical", "Major", "Minor"]}
          value={sonarQIssue}
          setValue={(value) => {
            setSonarQIssue(value);
          }}
          isAll={true}
          label="Select Team"
        /> */}
        <NormalSelect
          option={[
            { label: "Critical", value: "Critical" },
            { label: "Major", value: "Major" },
            { label: "Minor", value: "Minor" },
          ]}
          handleChange={(value) => {
            setSonarQIssue(value);
          }}
          value={sonarQIssue}
          style={{ width: "200px" }}
        />
      </div>
      <Grid container spacing={2} style={{ margin: "10px 0px" }}>
        <Grid item xs={5}>
          <Card className="position-relative pt-2">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mt-2 mx-4">
                Sonar Qube
                {/* <span
                onClick={() => {
                  setIsDefinaionPopup(true);
                  setDefinationKPI("Deployment Frequency (Count)");
                }}
              >
                <InfoIcon style={{ color: "gray", margin: "0px 8px" }} />
              </span> */}
                <span
                  className={classes.viewMore}
                  style={{ paddingLeft: "12px" }}
                  onClick={() => getMetricName("Sonar Qube")}
                >
                  View More
                </span>
              </h5>
              <h6 className="border mx-4 p-2">Static code backlog : {sonarQMetric?.total}</h6>
            </div>
            <Box width={"400px"} margin={"0px auto"}>
              <IssueTypeAllocationTrend
                graphData={sonarQMetric?.data}
                height={"200px"}
                idName="TestCoverage"
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Card style={{ marginTop: 20 }}>
        <div>
          {["Sonar Qube"].includes(SelectedMetricName) && (
            <RelevanceLabSprintTrend
              metricName={SelectedMetricName}
              // boardId={boardId}
              // sprintId={sprintId}
              allTeamList={[""]}
              projectId={projectId}
              date={date}
              filter={sonarQIssue}
            />
          )}
        </div>
      </Card>
      <Card style={{ marginTop: 20 }}>
        {["Sonar Qube"].includes(SelectedMetricName) && (
          <IssueLevel
            issueType={SelectedMetricName}
            assigneeList={[]}
            statusList={[]}
            subStatus={[]}
            projectId={projectId}
            autoScroll={true}
            isDate={true}
            isCheckList={false}
            filter={sonarQIssue}
          />
        )}
      </Card>
    </div>
  );
};

export default CodeHealth;
