import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Card, TextField } from "@material-ui/core";
import SelectFormFiled from "../../../components/select/selectFormFiled";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../../utils/axiosHelper";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";
import BreadCrumb from "../../../components/breadCrumb/BreadCrumb";
import { iterationList, rangeDuration } from "../../../utils/iteration";
import instance from "../../../utils/axiosHelper";

function ReportForm(props) {
  const validationSchema = yup.object({
    Reports: yup.array().min(1, "Please select at list one Reports").required("Required"),
    Projects: yup.array().min(1, "Please select at list one Project").required("Required"),
    Iteration: yup.string("").required("Required"),
    Email: yup.string("").email("Please enter valid Email").required("Required")
  });

  const { state } = useLocation();
  const history = useHistory();
  const [error, setError] = useState("");
  const [projectList, setProjectList] = useState([])
  const reportList = [
    { lable: " Discover Your Sprint Progress", value: 'Discover Your Sprint Progress' },
    { lable: "Resource Burnout", value: 'Resource Burnout' },
    { lable: "Resource Performance", value: 'Resource Performance' },
    { lable: "Top Performer", value: 'Top Performer' },
  ]
  const formik = useFormik({
    initialValues: {
      Reports: [],
      Projects: [],
      Iteration: "",
      Email: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let clientId = localStorage.getItem("ClientId");
      const payload = {
        ...values,
        ClientId: clientId
      }

      if (state) {
        updateHandler(payload);
      } else {
        submitHandler(payload);
      }
    },
  });
  const backHandler = () => {
    history.goBack();
  };

  const submitHandler = (payload) => {
    axios
      .post("/api/v2/reportGenerate", payload)
      .then((res) => {
        backHandler();
      })
      .catch((error) => {
        setError(error.response?.data?.error);
      });
  };
  const updateHandler = (payload) => {
    axios
      .put(`/api/v2/reportGenerate/${state.row._id}`, payload)
      .then((res) => {
        backHandler();
      })
      .catch((error) => {
        setError(error.response?.data?.error);
      });
  };

  useEffect(() => {
    if (state) {
      formik.setFieldValue("Reports", state.row.Reports);
      formik.setFieldValue("Projects", state.row.Projects.map((e) => e?.projectId));
      formik.setFieldValue("Iteration", state.row.Iteration);
      formik.setFieldValue(
        "Email",
        state.row.Email
      );
    }
  }, [state]);

  useEffect(() => {
    instance.get(`/api/v2/projects/list`).then((res) => {
      const data = res.data.map((e) => {
        return { lable: e.Name, value: e.Id }
      })
      setProjectList(data)
    }).catch((err) => {
      console.error(err)
    })
  }, [])

  return (
    <div>
      <BreadCrumb
        backHandler={() => {
          history.goBack();
        }}
        list={[]}
        active={state ? 'Update Report' : "New Report"}
      />
      <Card className="customCard">
        <form autoComplete="off" className="p-2" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SelectFormFiled
                withLable={true}
                render={reportList}
                value={formik.values.Reports}
                setValue={(val) => formik.setFieldValue("Reports", val)}
                label="Report"
                multiple={true}
              />
              <div className="errorMsg">
                {formik.errors.Reports &&
                  formik.touched.Reports &&
                  formik.errors.Reports}

              </div>
            </Grid>
            <Grid item xs={4}>
              <SelectFormFiled
                withLable={true}
                render={projectList}
                value={formik.values.Projects}
                setValue={(val) => formik.setFieldValue("Projects", val)}
                label="Project"
                multiple={true}
              />
              <div className="errorMsg">
                {formik.errors.Projects &&
                  formik.touched.Projects &&
                  formik.errors.Projects}

              </div>
            </Grid>
            <Grid item xs={4}>
              <SelectFormFiled
                withLable={true}
                label={"Iteration"}
                render={[...iterationList, { lable: "Daily", value: 1 }]}
                value={formik.values.Iteration}
                setValue={(val) => formik.setFieldValue("Iteration", val)}
              />
              <div className="errorMsg">
                {formik.errors.Iteration &&
                  formik.touched.Iteration &&
                  formik.errors.Iteration}

              </div>
            </Grid>
            {/* <Grid item xs={4}>
              <SelectFormFiled
                withLable={true}
                label={"Days Duration"}
                render={rangeDuration}
                value={formik.values.duration}
                setValue={(val) => formik.setFieldValue("duration", val)}
              />
              <div className="errorMsg">
                {formik.errors.duration &&
                  formik.touched.duration &&
                  formik.errors.duration}
              </div>
            </Grid> */}

            <Grid item xs={4}>
              <TextField
                id="Email"
                name="Email"
                className="border-bottom w-100"
                size="small"
                label="Email"
                value={formik.values.Email}
                onChange={(val) => formik.setFieldValue("Email", val.target.value)}
              />
              <div className="errorMsg">
                {formik.errors.Email &&
                  formik.touched.Email &&
                  formik.errors.Email}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                className="position-relative from-grid"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <button className={"cancelBtnStyle"} type="button" onClick={backHandler}>
                  <span>Cancel</span>
                </button>
                <button className={"applybtnStyle"} type="submit">
                  <span>{state ? 'Update' : "Apply"}</span>
                </button>
              </div>
            </Grid>
          </Grid>

          {error && (
            <div>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            </div>
          )}
        </form>
      </Card>
    </div>
  );
}

export default ReportForm;
