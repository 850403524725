import { Box, Card, CardContent, Grid, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GlobalData } from "../../context/globalData";
import InfoIcon from "@material-ui/icons/Info";
import { style } from "../Project/projectDashboard/style";
import HealthColorWrapper from "../../components/overview/healthColorWrapper";
import IssueTypeAllocationTrend from "../workAllocation/IssueTypeAllocationTrend";
import jkImg from "../../assets/jenkins_logo.png";
import IssueLevel from "../Project/projectDashboard/IssueLevel";
import Trend from "../Project/projectDashboard/trend";

const useStyles = style;

const HeaderAws = ({
  doraMetrics,
  projectId,
  riskAndRange,
  jobs,
  selectedTeam,
  jobList,
}) => {
  const classes = useStyles();
  const [issueType, setIssueType] = useState();
  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);
  const [DeploymentFreqArray, setDeploymentFreqArray] = useState([]);

  const viewMoreHandler = (name) => {
    setIssueType(name);
  };

  useEffect(() => {
    const getDeploymentFreq = doraMetrics?.data
      .filter((e) => e?.Id !== "count")
      .map((item) => {
        return {
          country: item?.Id,
          litres: item?.Value,
          additionalInfo: `${item?.Id}:${item?.Value} Count`,
        };
      });
    setDeploymentFreqArray(getDeploymentFreq);
  }, [doraMetrics]);

  return (
    <div>
      <Grid container spacing={2} className="my-4">
        <Grid item xs={5}>
          <Card className="position-relative">
            <h5 className="mt-2 mx-4">
              Deployment Frequency (AWS CICD){" "}
              <span
                onClick={() => {
                  setIsDefinaionPopup(true);
                  setDefinationKPI("Deployment Frequency (Count)");
                }}
              >
                <InfoIcon style={{ color: "gray", margin: "0px 8px" }} />
              </span>
              <span
                className={classes.viewMore}
                onClick={() => viewMoreHandler("Deployment Frequency")}
              >
                View More
              </span>
              <br />
              <strong>Count :{doraMetrics?.data[0].Value}</strong>
            </h5>
            <Tooltip
              title={
                <>
                  <h6>
                    Status: {riskAndRange?.DeploymentFrequencyRisk?.risk} Risk
                  </h6>
                  <h6>
                    Target:{riskAndRange?.DeploymentFrequencyRisk?.from}
                    (Count) to {riskAndRange?.DeploymentFrequencyRisk?.to}
                    (Count)
                  </h6>
                  <h6>Recommendations :</h6>
                  <ul>
                    <li>Strengthen the Ci/Cd Pipelines.</li>
                    <li>Prioritize automation in testing and deployment.</li>
                    <li>
                      Experiment with smaller, more frequency deployments to
                      gauge impact.
                    </li>
                  </ul>
                </>
              }
            >
              <div className="metricStatus">
                <HealthColorWrapper
                  status={riskAndRange?.DeploymentFrequencyRisk?.risk}
                ></HealthColorWrapper>
              </div>
            </Tooltip>
            <Box width={"400px"} margin={"0px auto"}>
              <IssueTypeAllocationTrend
                graphData={DeploymentFreqArray}
                height={"200px"}
                idName="awsService"
              />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={2} className="">
            {/* <Grid item xs={6}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={jkImg} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>
                    {doraMetrics?.deploymentFrequency} Count
                  </h3>
                  <h6>
                    Deployment Frequency (AWS CICD){" "}
                    <span
                      onClick={() => {
                        setIsDefinaionPopup(true);
                        setDefinationKPI("Deployment Frequency (Count)");
                      }}
                    >
                      <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                    </span>
                    <br />
                    <span
                      className={classes.viewMore}
                      onClick={() => viewMoreHandler("Deployment Frequency")}
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>
                          Status: {riskAndRange?.DeploymentFrequencyRisk?.risk}{" "}
                          Risk
                        </h6>
                        <h6>
                          Target:{riskAndRange?.DeploymentFrequencyRisk?.from}
                          (Count) to {riskAndRange?.DeploymentFrequencyRisk?.to}
                          (Count)
                        </h6>
                        <h6>Recommendations :</h6>
                        <ul>
                          <li>Strengthen the Ci/Cd Pipelines.</li>
                          <li>
                            Prioritize automation in testing and deployment.
                          </li>
                          <li>
                            Experiment with smaller, more frequency deployments
                            to gauge impact.
                          </li>
                        </ul>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <HealthColorWrapper
                        status={riskAndRange?.DeploymentFrequencyRisk?.risk}
                      ></HealthColorWrapper>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid> */}
            <Grid item xs={6}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={jkImg} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "24px" }}>
                        {" "}
                        {doraMetrics?.changeFailure} %
                      </h3>
                      <h6>
                        Change failure rate (AWS CICD )
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Change Failure Rate (%)");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                        <br />
                        <span
                          className={classes.viewMore}
                          onClick={() => viewMoreHandler("Change failure rate")}
                        >
                          View More
                        </span>
                      </h6>
                      <Tooltip
                        title={
                          <>
                            <h6>
                              Status:{" "}
                              {riskAndRange?.ChangeFailureRateRisk?.risk} Risk
                            </h6>
                            <h6>
                              Target:{riskAndRange?.ChangeFailureRateRisk?.from}
                              % to {riskAndRange?.ChangeFailureRateRisk?.to}%
                            </h6>{" "}
                            <h6>Recommendations :</h6>
                            <ul>
                              <li>
                                Analyse changes for patterns: Analyse recent
                                failed changes to pinpoint recurring issues.
                                Identifying patterns in past failures helps
                                address root causes, allowing the team to
                                implement targeted improvements and prevent
                                similar issues from happening again .
                              </li>
                              <li>
                                Foster a culture of collective accountability
                              </li>
                              <li>Embrace automation</li>
                            </ul>
                          </>
                        }
                      >
                        <div className="metricStatus">
                          <HealthColorWrapper
                            status={riskAndRange?.ChangeFailureRateRisk?.risk}
                          ></HealthColorWrapper>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item xs={6}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={jkImg} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "24px" }}>
                        {" "}
                        {doraMetrics?.deploymentRollback} (Hours)
                      </h3>
                      <h6>
                        Deployment rollback (AWS CICD)
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Deployment Rollback (Hours)");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                        <br />
                        <span
                          className={classes.viewMore}
                          onClick={() => viewMoreHandler("Deployment rollback")}
                        >
                          View More
                        </span>
                      </h6>
                      <Tooltip
                        title={
                          <>
                            <h6>
                              Status:{" "}
                              {riskAndRange?.DeploymentRollbackRisk?.risk} Risk
                            </h6>
                            <h6>
                              Target:
                              {riskAndRange?.DeploymentRollbackRisk?.from}
                              (Hours) to{" "}
                              {riskAndRange?.DeploymentRollbackRisk?.to}
                              (Hours)
                            </h6>
                            <h6>Recommendations :</h6>
                            <ul>
                              <li>
                                - Perform design reviews to make sure and
                                identify all dependencies.
                              </li>
                              <li>
                                Setup a deployment process to avoid outages like
                                implement blue-green deployments or gradual
                                rollouts.
                              </li>
                              <li>
                                For every rollback, do a RCA and fix the
                                identified issues.
                              </li>
                            </ul>
                          </>
                        }
                      >
                        <div className="metricStatus">
                          <HealthColorWrapper
                            status={riskAndRange?.DeploymentRollbackRisk?.risk}
                          ></HealthColorWrapper>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid> */}
            <Grid item xs={6}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={jkImg} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "24px" }}>
                        {" "}
                        {doraMetrics?.meanLeadTimeChange} (Hours)
                      </h3>
                      <h6>
                        Mean Lead Time for Change (AWS CICD)
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Lead Time for Changes");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                        <br />
                        <span
                          className={classes.viewMore}
                          onClick={() =>
                            viewMoreHandler("Mean Lead Time for Change")
                          }
                        >
                          View More
                        </span>
                      </h6>
                      <Tooltip
                        title={
                          <>
                            <h6>
                              Status: {riskAndRange?.MeanLeadTimeRisk?.risk}{" "}
                              Risk
                            </h6>
                            <h6>
                              Target:{riskAndRange?.MeanLeadTimeRisk?.from}
                              (Hours) to {riskAndRange?.MeanLeadTimeRisk?.to}
                              (Hours)
                            </h6>
                            <h6>Recommendations :</h6>
                            <ul>
                              <li>Use CI/CD and Continuous Merge</li>
                              <li>
                                Use engineering metrics benchmarks for targeted
                                improvement
                              </li>
                              <li>
                                Streamline communication within and across your
                                teams
                              </li>
                              <li>
                                Create a culture of continuous improvement by
                                scheduling regular retrospectives. In these
                                retrospectives, your team can work
                                collaboratively to identify and address
                                bottlenecks by analyzing past and present
                                development practices.
                              </li>
                            </ul>
                          </>
                        }
                      >
                        <div className="metricStatus">
                          <HealthColorWrapper
                            status={riskAndRange?.MeanLeadTimeRisk?.risk}
                          ></HealthColorWrapper>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={jkImg} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "24px" }}>
                        {" "}
                        {doraMetrics?.meanTimeToRestore} (Day's)
                      </h3>
                      <h6>
                        Mean Time To Restore (AWS CICD)
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Mean Time To Restore");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                        <br />
                        <span
                          className={classes.viewMore}
                          onClick={() =>
                            viewMoreHandler("Mean Time To Restore")
                          }
                        >
                          View More
                        </span>
                      </h6>
                      <Tooltip
                        title={
                          <>
                            <h6>
                              Status: {riskAndRange?.meanTimeToRestore?.risk}{" "}
                              Risk
                            </h6>
                            <h6>
                              Target:{riskAndRange?.meanTimeToRestore?.from}
                              (Day's) to {riskAndRange?.meanTimeToRestore?.to}
                              (Day's)
                            </h6>
                            <h6>Recommendations :</h6>
                            <ul>
                              <li>
                                Build out continuous integration/continuous
                                delivery (CI/CD) systems that automate
                                monitoring, testing, and failure detection.
                              </li>
                              <li>
                                Make small changes frequently. Deploying in
                                fixed intervals during the day ensures failures
                                can be easily tracked, and the changes that
                                cause failures can be fixed or rolled back.
                              </li>
                              <li>
                                Have the right tools, processes, and permissions
                                in place. The developers working on a project
                                should have all they need to fix issues
                                immediately.
                              </li>
                              <li>
                                Create a culture of continuous improvement by
                                scheduling regular retrospectives. In these
                                retrospectives, your team can work
                                collaboratively to identify and address
                                bottlenecks by analyzing past and present
                                development practices.
                              </li>
                            </ul>
                          </>
                        }
                      >
                        <div className="metricStatus">
                          <HealthColorWrapper
                            status={riskAndRange?.meanTimeToRestore?.risk}
                          ></HealthColorWrapper>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {[
        "Deployment Frequency",
        "Change failure rate",
        "Deployment rollback",
        "Mean Lead Time for Change",
        "Mean Time To Restore",
      ].includes(issueType) && (
        <Card style={{ marginTop: 20 }}>
          <Trend
            issueType={issueType}
            projectId={projectId}
            job={jobs}
            selectedTeam={selectedTeam}
          />
        </Card>
      )}
      {[
        "Deployment Frequency",
        "Change failure rate",
        "Deployment rollback",
        "Mean Lead Time for Change",
        "Mean Time To Restore",
      ].includes(issueType) && (
        <Card style={{ marginTop: 20 }}>
          <IssueLevel
            issueType={issueType}
            assigneeList={[]}
            statusList={["All", ...jobList]}
            subStatus={[]}
            projectId={projectId}
            job={jobs}
            selectedTeamId={selectedTeam}
            autoScroll={[
              "Deployment Frequency",
              "Change failure rate",
              "Deployment rollback",
              "Mean Lead Time for Change",
              "Mean Time To Restore",
            ].includes(issueType)}
            isDate={
              [
                "Deployment Frequency",
                "Change failure rate",
                "Deployment rollback",
                "Mean Lead Time for Change",
                "Mean Time To Restore",
              ].includes(issueType)
                ? true
                : false
            }
          />
        </Card>
      )}
    </div>
  );
};

export default HeaderAws;
