import { Box } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import EpicDropDown from "../../components/epicDropDown";
import RangeDatePicker from "../../components/RangeDatePicker";
import { GlobalData } from "../../context/globalData";
import DoraHeader from "./Header";
import {
  addLeadingZeroToDateRange,
  datePayload,
} from "../../utils/moduleTabApi";
import axios from "../../utils/axiosHelper";
import { getMetricRange } from "../../utils/getMetricRange";
import NormalSelect from "../../components/select/normalSelect";
import instance from "../../utils/axiosHelper";
import CheckSelect from "../../components/select/CheckSelect";
import HeaderAws from "./HeaderAws";

const Dora = ({ projectId }) => {
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [doraMetrics, setDoraMetrics] = useState();
  const [range, setRange] = useState();
  const [allTeamList, setAllTeamList] = useState([]);
  const [jobList, setAllJobList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  // Multiple Select
  // const [selectedTeamUrl, setSelectedTeamUrl] = useState();
  const [jobs, setJobs] = useState();
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  const doraMetricsNumbers = () => {
    const dateUrl = datePayload(date);
    const zeroDate = addLeadingZeroToDateRange(dateUrl);
    let url = `/api/v2/projects/doraMatric/${projectId}?${zeroDate}`;
    // Multiple Select
    // if (selectedTeamUrl) {
    //   url += `&${selectedTeamUrl}`;
    // }
    if (jobs) {
      url += `&Job=${jobs}`;
    }
    if (selectedTeam) {
      url += `&teamId=${selectedTeam}`;
    }
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        setDoraMetrics(res?.data[0]);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  // get ranges
  useEffect(() => {
    let url = `/api/risks?ProjectId=${projectId}`;
    if (epicData?.url) {
      url += epicData?.url;
    }
    axios
      .get(url)
      .then((result) => {
        setRange(result?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [projectId]);

  useEffect(() => {
    doraMetricsNumbers();
  }, [date, jobs, selectedTeam]);

  const DeploymentFrequencyRisk = useMemo(
    () =>
      getMetricRange(
        range,
        doraMetrics?.deploymentFrequency,
        "Deployment Frequency"
      ),
    [doraMetrics?.deploymentFrequency, range]
  );

  const ChangeFailureRateRisk = useMemo(
    () =>
      getMetricRange(range, doraMetrics?.changeFailure, "Change Failure Rate"),
    [doraMetrics?.changeFailure, range]
  );
  const DeploymentRollbackRisk = useMemo(
    () =>
      getMetricRange(
        range,
        doraMetrics?.deploymentRollback,
        "Deployment Rollback (Hour)"
      ),
    [doraMetrics?.deploymentRollback, range]
  );

  const MeanLeadTimeRisk = useMemo(
    () =>
      getMetricRange(
        range,
        doraMetrics?.meanLeadTimeChange,
        "Mean Lead Time (Hour)"
      ),
    [doraMetrics?.meanLeadTimeChange, range]
  );

  const MeanTimeToRestoreRisk = useMemo(
    () =>
      getMetricRange(
        range,
        doraMetrics?.meanTimeToRestore,
        "Mean Time To Restore (Day's)"
      ),
    [doraMetrics?.meanTimeToRestore, range]
  );

  console.log("MeanTimeToRestoreRisk", MeanTimeToRestoreRisk);

  // All Team List Api call
  useEffect(() => {
    const dateUrl = datePayload(date);
    const zeroDate = addLeadingZeroToDateRange(dateUrl);
    let url = `/api/v2/teams/list/kpis?${zeroDate}&iteration=7&project_id=${projectId}`;
    axios
      .get(url)
      .then((response) => {
        const list = response?.data.map((item) => {
          return { label: item?.TeamName, value: item?.Id };
        });
        setAllTeamList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // All Job List Api call
  useEffect(() => {
    let url = `/api/v2/projects/joblist/${projectId}`;
    axios
      .get(url)
      .then((response) => {
        const list = response?.data.map((item) => {
          return { label: item, value: item };
        });
        setAllJobList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Multiple Select
  // useEffect(() => {
  //   if (selectedTeam?.length > 0) {
  //     let url = " ";
  //     selectedTeam.forEach((e, index) => {
  //       if (index === 0) {
  //         url += `&teamName=${e}`;
  //       } else {
  //         url += `&teamName=${e}`;
  //       }
  //     });
  //     setSelectedTeamUrl(url.trim(), selectedTeam);
  //   } else {
  //     setSelectedTeamUrl("", selectedTeam);
  //   }
  // }, []);

  return (
    <div>
      <Box display={"flex"} gap={2}>
        {/* <EpicDropDown epicDropDownHandler={epicDropDownHandler} />  */}
        <>
          {/* select dropdown of month */}
          {/* <div>
              <NormalSelect
                option={[
                  { label: "Monthly", value: 30 },
                  { label: "Biweekly", value: 14 },
                  { label: "Weekly", value: 7 },
                  // { label: "Daily", value: 1 },
                ]}
                value={iteration}
                handleChange={(value) => setIteration(value)}
              />
            </div> */}
          <div>
            <label></label>
            <RangeDatePicker setDate={setDate} date={date} isNextDate={false} />
          </div>
          <div>
            <label>Select Jobs</label>
            <NormalSelect
              option={jobList}
              handleChange={(value) => {
                setJobs(value);
              }}
              value={jobs}
              // style={{ width: "200px" }}
            />
          </div>
          <div>
            {/* MultiSelect Team */}
            {/* <CheckSelect
              names={allTeamList}
              setValue={(value) => {
                setSelectedTeam(value);
              }}
              value={selectedTeam}
            /> */}
            <label>Select Team</label>
            <NormalSelect
              option={allTeamList}
              handleChange={(value) => {
                setSelectedTeam(value);
              }}
              value={jobs}
              // style={{ width: "200px" }}
            />
          </div>
        </>
      </Box>
      {doraMetrics?.DataSource === "AWS CICD" ? (
        <HeaderAws
          riskAndRange={{
            DeploymentFrequencyRisk: {
              risk: DeploymentFrequencyRisk?.RiskCategory,
              from: DeploymentFrequencyRisk?.From?.$numberDecimal,
              to: DeploymentFrequencyRisk?.To?.$numberDecimal,
            },
            ChangeFailureRateRisk: {
              risk: ChangeFailureRateRisk?.RiskCategory,
              from: ChangeFailureRateRisk?.From?.$numberDecimal,
              to: ChangeFailureRateRisk?.To?.$numberDecimal,
            },
            DeploymentRollbackRisk: {
              risk: DeploymentRollbackRisk?.RiskCategory,
              from: DeploymentRollbackRisk?.From?.$numberDecimal,
              to: DeploymentRollbackRisk?.To?.$numberDecimal,
            },
            MeanLeadTimeRisk: {
              risk: MeanLeadTimeRisk?.RiskCategory,
              from: MeanLeadTimeRisk?.From?.$numberDecimal,
              to: MeanLeadTimeRisk?.To?.$numberDecimal,
            },
            meanTimeToRestore: {
              risk: MeanTimeToRestoreRisk?.RiskCategory,
              from: MeanTimeToRestoreRisk?.From?.$numberDecimal,
              to: MeanTimeToRestoreRisk?.To?.$numberDecimal,
            },
          }}
          doraMetrics={doraMetrics}
          projectId={projectId}
          jobs={jobs}
          selectedTeam={selectedTeam}
          jobList={jobList.map((e) => e?.label)}
        />
      ) : (
        <DoraHeader
          riskAndRange={{
            DeploymentFrequencyRisk: {
              risk: DeploymentFrequencyRisk?.RiskCategory,
              from: DeploymentFrequencyRisk?.From?.$numberDecimal,
              to: DeploymentFrequencyRisk?.To?.$numberDecimal,
            },
            ChangeFailureRateRisk: {
              risk: ChangeFailureRateRisk?.RiskCategory,
              from: ChangeFailureRateRisk?.From?.$numberDecimal,
              to: ChangeFailureRateRisk?.To?.$numberDecimal,
            },
            DeploymentRollbackRisk: {
              risk: DeploymentRollbackRisk?.RiskCategory,
              from: DeploymentRollbackRisk?.From?.$numberDecimal,
              to: DeploymentRollbackRisk?.To?.$numberDecimal,
            },
            MeanLeadTimeRisk: {
              risk: MeanLeadTimeRisk?.RiskCategory,
              from: MeanLeadTimeRisk?.From?.$numberDecimal,
              to: MeanLeadTimeRisk?.To?.$numberDecimal,
            },
            meanTimeToRestore: {
              risk: MeanTimeToRestoreRisk?.RiskCategory,
              from: MeanTimeToRestoreRisk?.From?.$numberDecimal,
              to: MeanTimeToRestoreRisk?.To?.$numberDecimal,
            },
          }}
          doraMetrics={doraMetrics}
          projectId={projectId}
          jobs={jobs}
          selectedTeam={selectedTeam}
        />
      )}
    </div>
  );
};

export default Dora;
