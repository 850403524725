import { PROJEECT_API_URL_GEN } from "../../utils/projectTabApi";
import { useEffect, useState } from "react";
import axios from "../../utils/axiosHelper";
import { iterationMap } from "../../utils/textMap";
import { useContext } from "react";
import { GlobalData } from "../../context/globalData";
import InfoIcon from "@material-ui/icons/Info";
import RiskWrapper from "../../components/overview/riskWrapper";
import { riskMap } from "../../utils/levelMap";
import { Card } from "@material-ui/core";
import Template from "./pageLayout";
import BreadCrumb from "../../components/breadCrumb/BreadCrumb";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import GroupIcon from "@material-ui/icons/Group";
import { Metric } from "../../utils/textMap";
import { useHistory } from "react-router-dom";
import PointerWrapper from "../../components/overview/pointerWrapper";
import { makeStyles } from "@material-ui/core/styles";
import check from "../../../src/assets/check.png";
import msg from "../../../src/assets/msg.png";
import pr from "../../../src/assets/pr.png";
import commit from "../../../src/assets/commit.png";
import flowEfficiencyImg from "../../../src/assets/FlowEfficiency.jpeg";
import WIPimg from "../../../src/assets/WIP.jpeg";
import {
  Avatar,
  AvatarGroup,
  Box,
  CardContent,
  Grid,
  Tooltip,
} from "@mui/material";
import { fix } from "../../utils/changeDecimal";
import WarningIcon from "@mui/icons-material/Warning";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { config } from "../../Config/permission";
import CheckSelect from "../../components/select/CheckSelect";
import MultiSelectWithAll from "../../components/MultiSelectWithAll/NormalCheck";

const useStyles = makeStyles((theme) => ({
  anchor: {
    cursor: "pointer",
    color: "#5EBDCC",
    display: "flex",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  cardContaner: {
    display: "flex",
    alignItems: "center",
    gap: "14px",
  },
  card: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  viewMore: {
    cursor: "pointer",
    color: "blue",
    textDecoration: "underline",
  },
  icon: {
    backgroundColor: "#eff8fa",
    width: "36px",
    height: "36px",
    "border-radius": "50%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    "& img": {
      maxWidth: "25px",
      maxHeight: "25px",
    },
  },
}));

export default function ProjectPage(props) {
  const {
    iterationValueGlobal,
    setModuleKpiFlag,
    projectRangePickerValue: rangePickerValue,
    setProjectRangePickerValue: setRangePickerValue,

    setSelectedProjectModuleName,
    selectedProjectModuleName,
    setIsDefinaionPopup,
  } = useContext(GlobalData);

  const [projectDetailData, setProjectDetailData] = useState();
  const [materialTableColums, setMaterialTableColums] = useState([]);
  const [kpiModalMaterialTableColums, setKpiModalMaterialTableColums] =
    useState([]);
  const [selectModuleName, setSelectModuleName] = useState();
  const [metricValue, setMetricValue] = useState("Metric Details");
  const [projectId, setProjectId] = useState();
  const [kpiData, setKpidata] = useState();
  const [kpiName, setKpiName] = useState();
  const [openModalType, setOpenModalTyle] = useState();
  const [selectedProject, setSelectedProject] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedResource, setSelectedResource] = useState([]);

  const history = useHistory();
  const classes = useStyles();

  const tableOption = {
    sorting: true,
    search: true,
    toolbar: true,
    // paging: false,
    searchFieldStyle: {
      padding: "5px 10px 5px 15px",
      borderRadius: "30px",
      disableUnderline: true,
      backgroundColor: "#efefef",
    },
  };

  var colors = [
    "#5EBDCC",
    "#8DDBE8",
    "#9DE6F2",
    "#2E7E8B",
    "#1B515A",
    "#33FFFF",
  ];

  const moduleApiHandler = (Module_URL) => {
    axios
      .get(Module_URL)
      .then((result) => {
        let data = [];
        if (metricValue == "Metric Details") {
          setProjectDetailData(result.data);
        } else {
          result.data?.forEach((e) => {
            let value = { name: e.OProjectName, risk: e.riskLevel };
            e.metrics.forEach((item) => {
              value[item.name] = item.RiskLevel;
            });
            data.push(value);
          });
          setProjectDetailData(data);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const popuUpHandler = (row, type) => {
    setSelectModuleName(row?.Name);
    setProjectId(row.Id);
    setSelectedProjectModuleName(row?.Name);
    setModuleKpiFlag(true);
    setOpenModalTyle(type);
    if (type === "kpi") {
      getMatrickDetails(row.Id, row?.Name);
    } else {
      getResouceDetails(row?.Id, row?.Name);
    }
  };

  // redirect to metric detail pae
  const graphPageRedirectHandler = (value, projectId, projectName) => {
    history.push(
      `/project/${projectId}/kpi/${value?.Id}?matricName=${value?.name}&ProjectName=${projectName}`
    );
    setModuleKpiFlag(false);
  };

  // redirect to resource detail page
  const graphPageRedirectResourceHandler = (
    rowData,
    projectId,
    projectName
  ) => {
    console.log(rowData, projectId, projectName, "new");
    history.push({
      pathname: `/project/${projectId}/single-resource/${rowData?.Id}`,
      state: {
        projectName: projectName,
        resourceName: rowData?.ResourceName,
        teamName: rowData?.TeamName,
      },
    });
    setModuleKpiFlag(false);
  };

  // defination popup handler
  const definationPopupHandler = (value, projectId, projectName) => {
    setKpiName(value?.name);
    setIsDefinaionPopup(true);
  };

  // ==================metric list start=====================
  const getMatrickDetails = (projectId, projectName) => {
    let Module_URL = PROJEECT_API_URL_GEN(
      rangePickerValue,
      projectId,
      // iterationMap[iterationValueGlobal]
      7
    );
    setKpidata([]);
    const data = kpiColumns(projectId, projectName);
    setKpiModalMaterialTableColums(data);
    let url = `api/v2/projects/list/${projectId}/kpis?${Module_URL}`;
    axios
      .get(url)
      .then((res) => {
        setKpidata(res.data.metrics);
      })
      .catch((error) => {
        setModuleKpiFlag(false);
      });
  };
  const kpiColumns = (projectId, projectName) => [
    {
      field: "",
      title: "Sr.No.",
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
      headerStyle: {
        textAlign: "center",
        backgroundColor: "#efefef",
      },
      cellStyle: {
        textAlign: "center",
        fontSize: "12px",
      },
      width: "7%",
    },
    {
      field: "name",
      title: "KPI Name",
      render: (value) => (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              definationPopupHandler(value, projectId, projectName)
            }
          >
            <InfoIcon style={{ color: "rgb(203 196 196)" }} fontSize="small" />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              graphPageRedirectHandler(value, projectId, projectName)
            }
          >
            {value?.name} <ChevronRightIcon />
          </div>
        </div>
      ),
      headerStyle: {
        textAlign: "left",
        backgroundColor: "#efefef",
      },
      cellStyle: {
        color: "#5EBDCC",
        fontSize: "12px",
      },
    },
    {
      field: "Value",
      title: "Value",
      cellStyle: {
        textAlign: "center",
        fontSize: "12px",
      },
      render: (value) => <b>{fix(value?.Value)}</b>,
      headerStyle: {
        textAlign: "center",
        backgroundColor: "#efefef",
      },
      width: "12%",
    },
    {
      field: "Target",
      title: "Target",
      cellStyle: {
        textAlign: "center",
        fontSize: "12px",
      },
      render: (value) => <b>{value?.Target}</b>,
      headerStyle: {
        textAlign: "center",
        backgroundColor: "#efefef",
      },
      width: "12%",
    },
    {
      field: "RiskLevel",
      title: "Risk",
      render: (value) => (
        <RiskWrapper val={riskMap[value?.RiskLevel]}>
          {riskMap[value?.RiskLevel]}
        </RiskWrapper>
      ),
      cellStyle: {
        textAlign: "left",
        fontSize: "12px",
      },
      headerStyle: {
        textAlign: "center",
        backgroundColor: "#efefef",
      },
      width: "12%",
    },
  ];
  // ==================metric list end=====================

  // ==================resource list start=====================
  // get resouorce list
  const getResouceDetails = (projectId, projectName) => {
    let Module_URL = PROJEECT_API_URL_GEN(
      rangePickerValue,
      projectId,
      // iterationMap[iterationValueGlobal]
      7
    );
    setKpidata([]);
    const data = resourceColumns(projectId, projectName);
    const columns = [...data];
    let url = `/api/v2/resource/list/kpis?${Module_URL}&project_id=${projectId}`;
    axios
      .get(url)
      .then((res) => {
        const result = res.data;
        const data = [];
        result.forEach((e) => {
          const columnData = {
            ResourceName: e.ResourceName,
            TeamName: e.TeamName,
            Id: e.Id,
            RiskLevel: e.RiskLevel,
          };

          // Add metric names to the columnData object
          e?.metrics?.forEach((item, index) => {
            if (index > 2) return;
            const targetFrom = item?.risks?.find((t) => t?.RiskLevel == 1);
            columnData[item.name] = {
              name: item.Value,
              risk: item.RiskLevel,
              id: item.Id,
              from: targetFrom?.From,
              to: targetFrom?.To,
            };
          });
          data.push(columnData);
        });
        Object.keys(data[0])?.forEach((item, index) => {
          if (
            item !== "ResourceName" &&
            item !== "TeamName" &&
            item !== "tableData" &&
            item !== "Id" &&
            item !== "RiskLevel"
          ) {
            columns.push({
              title: item,
              field: item,
              width: "150px",
              headerStyle: {
                textAlign: "left",
                backgroundColor: "#efefef",
              },
              render: (rowData) => (
                <Tooltip
                  title={
                    <h6>
                      Target:{" "}
                      {rowData[item]?.from !== null &&
                      rowData[item]?.from !== undefined
                        ? `${rowData[item]?.from}%`
                        : " - "}
                      {" to "}
                      {rowData[item]?.to !== null &&
                      rowData[item]?.to !== undefined
                        ? `${rowData[item]?.to}%`
                        : " - "}
                    </h6>
                  }
                >
                  <div>
                    <PointerWrapper val={riskMap[rowData[item]?.risk]}>
                      {fix(rowData[item]?.name)}&nbsp;
                      {/* <ChevronRightIcon /> */}
                    </PointerWrapper>
                  </div>
                </Tooltip>
              ),
            });
          }
        });
        setKpiModalMaterialTableColums(columns);
        setKpidata(data);
      })
      .catch((error) => {
        setModuleKpiFlag(false);
      });
  };
  // generate resource list columns
  const resourceColumns = (projectId, projectName) => [
    {
      field: "",
      title: "Sr.No.",
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
      headerStyle: {
        textAlign: "center",
        backgroundColor: "#efefef",
      },
      cellStyle: {
        textAlign: "center",
        fontSize: "12px",
        color: "#000000",
      },
    },
    {
      field: "ResourceName",
      title: "Contributor Name",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer", color: "#5EBDCC" }}
          onClick={() =>
            graphPageRedirectResourceHandler(rowData, projectId, projectName)
          }
        >
          {rowData.ResourceName}
        </div>
      ),
      headerStyle: {
        textAlign: "left",
        backgroundColor: "#efefef",
      },
      cellStyle: {
        color: "#5EBDCC",
        fontSize: "12px",
        color: "#000000",
      },
      width: "200px",
    },
  ];
  // =====================resource list End==================

  // to call summary and detail api
  useEffect(() => {
    let Module_URL = PROJEECT_API_URL_GEN(
      rangePickerValue,
      projectId,
      iterationMap[iterationValueGlobal]
    );
    if (metricValue == "Metric Details") {
      let url = `/api/v2/projects/details?${Module_URL}`;
      moduleApiHandler(url);
    } else {
      let url = `/api/project/summary-detail?${Module_URL}&group_id=4`;
      moduleApiHandler(url);
    }
  }, [metricValue]);

  // to set main table columns
  useEffect(() => {
    if (!projectDetailData) return;
    if (metricValue == "Metric Details") {
      let columns = [
        {
          field: "Name",
          title: "Project Name",
          headerStyle: {
            width: "400px",
            backgroundColor: "#efefef",
          },
          cellStyle: {
            width: "400px",
            color: "#5EBDCC",
            cursor: "pointer",
          },
          width: "200px",
          render: (value) => (
            <div
              className={classes.anchor}
              onClick={() =>
                history.push(`/project/${value.Id}?projectName=${value.Name}`)
              }
            >
              {value.Name} <ChevronRightIcon />
            </div>
          ),
        },
        {
          field: "teams[0].Name",
          title: "Teams",
          cellStyle: {
            color: "#5EBDCC",
            cursor: "pointer",
          },
          render: (value) =>
            value.teams ? (
              <div
                onClick={() =>
                  history.push({
                    pathname: `/project/${value.Id}`,
                    search: `?projectName=${value.Name}`,
                    state: { data: 1 },
                  })
                }
              >
                {value?.teams[0] && value?.teams[0]?.Name}
                {value?.teams?.length > 1 && ` + ${value?.teams?.length - 1}`}
              </div>
            ) : (
              ""
            ),
          headerStyle: {
            backgroundColor: "#efefef",
          },
        },
        {
          field: "resources[0].Name",
          title: "Contributor",
          render: (value) =>
            value?.resources?.length > 0 ? (
              <Box>
                <AvatarGroup
                  total={value?.resources?.length || 0}
                  max={4}
                  sx={{ justifyContent: "flex-end" }}
                >
                  {value?.resources?.map((e, i) => (
                    <Avatar sx={{ bgcolor: colors[i] }} key={i}>
                      {e?.NameInitials}
                    </Avatar>
                  ))}
                </AvatarGroup>
              </Box>
            ) : (
              "Contributor Are Not Assigned For This Project"
            ),
          cellStyle: {
            textAlign: "center",
            cursor: "pointer",
          },
          headerStyle: {
            textAlign: "center",
            backgroundColor: "#efefef",
          },
        },
        {
          field: "modules[0].Name",
          title: "Module",
          headerStyle: {
            backgroundColor: "#efefef",
          },
        },
      ];

      // Modify the array before setting it in state
      if (config.project.projectLevelAllProjectLifeTimeProgress) {
        columns.push({
          field: "LifeTimeProgress",
          title: "Life Time Progress (%)",
          headerStyle: {
            backgroundColor: "#efefef",
          },
        });
      }
      if (config.project.projectLevelAllProjectOverallRisk) {
        columns.push({
          field: "Risk",
          title: "Overall Risk (%)",
          headerStyle: {
            backgroundColor: "#efefef",
          },
          render: (value) => (
            <>
              <FiberManualRecordIcon sx={{ color: value?.RiskColor }} />{" "}
              {value?.OverAllProjectRisk ? value?.OverAllProjectRisk : 0}{" "}
            </>
          ),
        });
      }

      // Now set the modified columns array
      setMaterialTableColums(columns);
    } else {
      const columns = [
        {
          field: "name",
          title: "Project Name",
          headerStyle: {
            backgroundColor: "#efefef",
          },
          width: "350px",
        },
        {
          field: "Quality",
          title: "Quality",
          render: (value) => (
            <RiskWrapper val={riskMap[value?.Quality]}>
              {riskMap[value?.Quality]}
            </RiskWrapper>
          ),
          cellStyle: {
            textAlign: "center",
          },
          headerStyle: {
            textAlign: "left",
            backgroundColor: "#efefef",
          },
        },
        {
          field: "Security",
          title: "Security",
          render: (value) => (
            <RiskWrapper val={riskMap[value?.Security]}>
              {riskMap[value?.Security]}
            </RiskWrapper>
          ),
          cellStyle: {
            textAlign: "center",
          },
          headerStyle: {
            textAlign: "left",
            backgroundColor: "#efefef",
          },
        },
        {
          field: "Timeliness",
          title: "Timeliness",
          render: (value) => (
            <RiskWrapper val={riskMap[value?.Timeliness]}>
              {riskMap[value?.Timeliness]}
            </RiskWrapper>
          ),
          cellStyle: {
            textAlign: "center",
          },
          headerStyle: {
            textAlign: "left",
            backgroundColor: "#efefef",
          },
        },
        {
          field: "risk",
          title: "Risk",
          render: (value) => (
            <RiskWrapper val={riskMap[value?.riskLevel]}>
              {riskMap[value?.risk]}
            </RiskWrapper>
          ),
          cellStyle: {
            textAlign: "center",
          },
          headerStyle: {
            textAlign: "left",
            backgroundColor: "#efefef",
          },
        },
      ];
      setMaterialTableColums(columns);
    }
  }, [projectDetailData, metricValue]);

  useEffect(() => {
    if (!openModalType) return;

    if (openModalType === "kpi") {
      getMatrickDetails(projectId, selectedProjectModuleName);
    } else {
      getResouceDetails(projectId, selectedProjectModuleName);
    }
  }, [rangePickerValue, iterationValueGlobal]);

  const getSelectedProject = (select) => {
    setSelectedProject(select);
  };
  const getSelectedTeam = (select) => {
    setSelectedTeam(select);
  };
  const getSelectedResource = (select) => {
    setSelectedResource(select);
  };

  return (
    <>
      <BreadCrumb list={[]} active={"Projects"} />
      <Card className="resourceStyling paginationStyling customCard">
        <Template
          rangePickerValue={rangePickerValue} // range picker value
          setRangePickerValue={setRangePickerValue} // set range picker value
          Metric={Metric} // drop down data
          metricValue={metricValue} // selected type of table list
          setMetricValue={setMetricValue} // select type of table graph
          dropdownProjectList={[]} // project list
          setTitleID={""} //select project
          projectId={""} // selected project
          moduleDetailData={projectDetailData} // table data
          materialTableColums={materialTableColums} // table colums
          moduleKpiData={kpiData}
          kpiModalMaterialTableColums={kpiModalMaterialTableColums}
          isProject={false} // is project dropdown visible
          tableOption={tableOption} // table option
          selectModuleName={selectModuleName} //model heading text
          kpiName={kpiName}
          isMetricValue={false}
          isDate={false}
          isItration={false}
        />
      </Card>
      {/* <div className="mt-3">
        <MultiSelectWithAll
          onChange={getSelectedProject}
          placeholder="Select Project"
          value={selectedProject}
          options={[
            "Supply Chain Logistics",
            "Data Migration",
            "Customer Support",
          ]}
          isAllCheck={true}
        />
        {selectedProject.length > 0 && (
          <MultiSelectWithAll
            onChange={getSelectedTeam}
            placeholder="Select Team"
            value={selectedTeam}
            options={[
              "Team India",
              "Data Engineering",
              "Mobile Team",
              "QA Team",
              "Singapore Team",
            ]}
            style={{ width: "300px", margin: "0px 20px" }}
          />
        )}
        {selectedTeam.length > 0 && (
          <MultiSelectWithAll
            onChange={getSelectedResource}
            placeholder="Select Resources"
            value={selectedResource}
            options={[
              "aditya.chavan",
              "Arief Akbar Hidayat",
              "Devyani Dubey",
              "Feng Xiao",
              "Ghanu Agoramoorthy",
              "Girish Premchandran",
              "Ravindra Tripathi",
              "Ravindu Hasantha",
            ]}
          />
        )}
      </div>
      <Grid container spacing={2} className="my-4">
        {config.project.projectLevelMetricWorkCompleted && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#e8f6eb" }}
                  >
                    <img src={check} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>12</h3>
                    <h6>Work Complete</h6>
                    <h6>({config.type} )</h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricCommitWithoutTicketRef && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={msg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>506/6989 (7.24 %)</h3>
                    <h6>
                      Commit Without Ticket Reference
                      <span>
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span className={classes.viewMore}></span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricUnReviewPr && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={pr} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>23/311 (7.4 %)</h3>
                    <h6>
                      Unreviewed PR
                      <span>
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span className={classes.viewMore}></span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricCommitWithPr && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={commit} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>949/6989 (13.58 %)</h3>
                    <h6>
                      Commit With Pull Request
                      <span>
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span className={classes.viewMore}></span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricsCommitWithoutPr && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={commit} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>6040/6989 (86.42 %)</h3>
                    <h6>
                      Commit Without Pull Request
                      <span>
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span className={classes.viewMore}></span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricPrReviewTime && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={commit} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>6 (Days)</h3>
                    <h6>
                      Pr Review Time
                      <span>
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span className={classes.viewMore}></span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricFirstTimePassIssue && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={commit} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>634/798 (79 %)</h3>
                    <h6>
                      First Time Pass Issue
                      <span>
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span className={classes.viewMore}></span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricWorkInProgress && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={WIPimg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <>
                      <div
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        <div>
                          Work In Progress - 45
                          <span>
                            <InfoIcon
                              style={{ color: "gray", marginLeft: "8px" }}
                            />
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "18px",
                        }}
                        className="mb-1"
                      >
                        <div> Avg Age - 75 day's</div>
                      </div>
                    </>
                    <h6>
                      <span className={classes.viewMore}></span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricAvgFlowEfficiency && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={flowEfficiencyImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    48.27 %
                    <h6>
                      Average Flow Efficiency
                      <span>
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span className={classes.viewMore}></span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricSpeedingTransitionRate && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={WIPimg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>915/937 (98 %)</h3>
                    <h6>
                      Speeding Transitions Rate
                      <span>
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span className={classes.viewMore}></span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricsStoryTicketWithoutEpicAndStory && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={WIPimg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>43</h3>
                    <h6>
                      Ticket Without Epics/Stories 
                      <span>
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span className={classes.viewMore}></span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid> */}
    </>
  );
}
