import React, { useEffect, useRef, useState } from "react";
import { datePayload } from "../../../../../utils/moduleTabApi";
import instance from "../../../../../utils/axiosHelper";
import TrendBar from "../TrendBar";
import { Box, Card } from "@mui/material";
import SelectField from "../../../../../components/select/selectField";
import { format } from "date-fns";
import StackedColumnChart from "../../../../../components/stackedColumnChart/StackedColumnChart";
import MultipleCompareBar from "./MultipleCompareBar";
import { config } from "../../../../../Config/permission";

const RelevanceLabSprintTrend = ({
  metricName,
  boardId,
  sprintId,
  allTeamList,
  projectId,
  date,
  filter,
}) => {
  const [data, setData] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(allTeamList[0]);
  const [storyBurndownValues, setStoryBurndownValues] = useState({});
  const trendDiv = useRef(null);

  // Multi selection Dropdowns
  // useEffect(() => {
  //   if (selectedTeam?.length > 0) {
  //     let url = " ";
  //     selectedTeam.forEach((e, index) => {
  //       if (index === 0) {
  //         url += `&teamName=${e}`;
  //       } else {
  //         url += `&teamName=${e}`;
  //       }
  //     });
  //     setSelectedTeamUrl(url.trim(), selectedTeam);
  //   } else {
  //     setSelectedTeamUrl("", selectedTeam);
  //   }
  // }, [selectedTeam]);

  useEffect(() => {
    const dateUrl = datePayload(date);
    if (metricName === "Defect injection") {
      let url = `/api/v2/sprints/trend/defectInjection/?board_id=${boardId}&sprint_id=${sprintId}`;
      trendApiCall(url);
    } else if (
      metricName === "Sprint Execution Efficiency" ||
      metricName === "Velocity Vs Commitment"
    ) {
      let url = `/api/v2/sprints/trend/sprintExecution/?board_id=${boardId}&sprint_id=${sprintId}`;
      trendApiCall(url);
    } else if (metricName === "Scope Changes") {
      let url = `/api/v2/sprints/trend/scopeChange/?board_id=${boardId}&sprint_id=${sprintId}`;
      trendApiCall(url);
    } else if (metricName === "Team Efficiency") {
      let url = `/api/v2/sprints/trend/teamEfficiency?project_id=${projectId}&board_id=${boardId}&sprint_id=${sprintId}&teamName=${selectedTeam}`;
      trendApiCall(url);
    } else if (metricName === "Defect Injection") {
      let url = `/api/v2/projects/trend/defectInjection/${projectId}?iteration=30&${dateUrl}`;
      trendApiCall(url);
    } else if (metricName === "Defect Slippage") {
      let url = `/api/v2/projects/trend/defectSlippage/${projectId}?iteration=30&${dateUrl}`;
      trendApiCall(url);
    } else if (metricName === "Story Burndown") {
      let url = `/api/v2/projects/trend/storyBurndown/${projectId}?${dateUrl}&iteration=30`;
      trendApiCall(url);
    } else if (metricName === "Test Coverage") {
      let url = `/api/v2/projects/trend/testCodeCoverage/${projectId}?iteration=30&${dateUrl}`;
      trendApiCall(url);
    } else if (metricName === "Sonar Qube") {
      let url = `/api/v2/projects/trend/sonarQubeMetric/${projectId}?${dateUrl}`;
      if (filter) {
        url += `&filter=${filter}`;
      }
      trendApiCall(url);
    } else if (metricName === "Well Formed Stories") {
      let url = `/api/v2/projects/trend/wellFormedStories/${projectId}?iteration=30&${dateUrl}&board_id=140`;
      trendApiCall(url);
    }
  }, [metricName, boardId, sprintId, selectedTeam, date]);

  const scrollToDiv = () => {
    if (trendDiv.current) {
      trendDiv.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const trendApiCall = (url) => {
    instance
      .get(url)
      .then((response) => {
        if (metricName === "Defect injection") {
          const ApiData = response?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "No of New Defect": e?.NewDefects,
              "No of Story Defect": e?.CompletedDefects,
            };
          });
          setData(ApiData);
        } else if (metricName === "Sprint Execution Efficiency") {
          const ApiData = response?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Final Commitment": e?.Commited,
              Completed: e?.Completed,
            };
          });
          setData(ApiData);
        } else if (metricName === "Velocity Vs Commitment") {
          const ApiData = response?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Completed Stories": e?.Completed,
              "Committed Stories": e?.Commited,
            };
          });
          setData(ApiData);
        } else if (metricName === "Scope Changes") {
          console.log(response);
          const ApiData = response?.data?.map((e) => {
            return {
              category: e?.StartDate,
              "Scope Added": e?.ScopeAdded,
              "Scope Removed": e?.ScopeRemoved,
            };
          });
          setData(ApiData);
        } else if (metricName === "Team Efficiency") {
          console.log(response);
          const ApiData = response?.data?.map((e) => {
            return {
              category: e?.StartDate,
              // Committed: e?.Commited,
              // Completed: e?.Completed,
              "Team Efficiency %": e?.TeamEfficiencyPercent,
            };
          });
          setData(ApiData);
        } else if (metricName === "Defect Injection") {
          console.log(response);
          const ApiData = response?.data?.map((e) => {
            return {
              category: e?.SprintName,
              "No of New Defect": e?.NewDefects,
              "No of Story Completed": e?.CompletedDefects,
            };
          });
          setData(ApiData);
        } else if (metricName === "Defect Slippage") {
          console.log(response);
          const ApiData = response?.data?.map((e) => {
            return {
              category: e?.SprintName,
              "Defect Slippage %": e?.PreReleaseDefects,
            };
          });
          setData(ApiData);
        } else if (metricName === "Story Burndown") {
          console.log(response);
          const ApiData = response?.data?.trendData?.map((e) => {
            return {
              category: e?.StartDate ?? "null",
              "Work Done": e?.CompletedWork ?? 0,
              "Total Work": e?.TotalWork ?? 0,
              "Scope Removed": +e?.ScopeRemoved ?? 0,
              "Scope Added": e?.ScopeAdded ?? 0,
            };
          });
          setData(ApiData);
          setStoryBurndownValues(response?.data);
        } else if (metricName === "Test Coverage") {
          console.log(response);
          const ApiData = response?.data?.map((e) => {
            return {
              category: e?.SprintName,
              "Manual Tests": e?.ManualTests,
              "Automation Tests": e?.AutomationTests,
            };
          });
          setData(ApiData);
        } else if (metricName === "Sonar Qube") {
          const ApiData = response?.data?.map((e) => {
            return {
              category: e?.SprintName,
              "Code Smell": e?.CodeSmells,
              Vulnerabilities: e?.Vulnerabilities,
              Bugs: e?.Bugs,
            };
          });
          setData(ApiData);
        } else if (metricName === "Well Formed Stories") {
          console.log(response);
          const ApiData = response?.data?.map((e) => {
            return {
              category: e?.SprintName,
              "% Of Well Formed Stories": e?.wellFormedStories,
            };
          });
          setData(ApiData);
        }
        scrollToDiv();
      })
      .catch((err) => {
        console.log(err, `${metricName} error`);
      });
  };

  const yAxesLabel = (data) => {
    switch (data) {
      case "Defect injection":
      case "Project Defect Injection":
        return "No of New Defects";
      case "Project Defect Slippage":
        return "Defect Slippage %";
      case "Sprint Execution Efficiency":
        return "Final Commitment vs Completed";
      case "Velocity Vs Commitment":
        return "Completed Stories";
      case "Scope Changes":
        return "Increased and Decreased %";
      case "Team Efficiency":
        // return "Increased and Decreased %";
        return "Team Efficiency %";
      case "Story Burndown":
        return `${config.type}`;
      case "Test Coverage":
        return "Test Cases Count";
      case "Sonar Qube":
        return "vulnerability vs Bugs vs Code Smells";
      case "Well Formed Stories":
        return "% of Well Formed Stories";
      default:
        return "return";
    }
  };

  const yAxesLabelText = yAxesLabel(metricName);

  const getTrends = (trendName, TrendData) => {
    switch (trendName) {
      case "Defect Slippage":
      case "Defect Injection":
      case "Well Formed Stories":
        return (
          <>
            <TrendBar
              graphData={TrendData}
              yAxesLabel={yAxesLabelText}
              height={"800px"}
            />
          </>
        );
      //   case "Team Efficiency":
      //     return (
      //       <>
      //         {trendName === "Team Efficiency" ? (
      //           <Box display={"flex"} justifyContent={"end"} alignItems={'center'}>
      //             <div className="border p-2 d-flex ">
      //               <div>
      //                 <SquareIcon style={{color: "green"}} />
      //                 <span>Good Efficiency</span>
      //               </div>
      //               <div className="mx-2">
      //                 <SquareIcon style={{color: "#ebeb00"}} />
      //                 <span>Average Efficiency</span>
      //               </div>
      //               <div>
      //                 <SquareIcon style={{color: "red"}} />
      //                 <span>Need to Improve</span>
      //               </div>
      //             </div>
      //             <SelectField
      //               render={allTeamList}
      //               value={selectedTeam}
      //               setValue={(value) => {
      //                 setSelectedTeam(value);
      //               }}
      //               isAll={true}
      //               label="Select Team"
      //             />
      //           </Box>
      //         ) : null}
      //         <CustomTrendBar
      //           graphData={TrendData}
      //           yAxesLabel={yAxesLabelText}
      //           height={"800px"}
      //           rangeAndColor={{
      //             graterValues: 80,
      //             lessValues: 60,
      //             graterBetweenValues: 60,
      //             lessBetweenValues: 80,
      //             graterColor: "green",
      //             lessColor: "red",
      //             inBetweenColor: "#ebeb00",
      //           }}
      //         />
      //       </>
      //     );
      // case "Sprint Execution Efficiency":
      //   return (
      //     <>
      //       <YearlyLineChart data={TrendData} yAxisLabel={yAxesLabelText} />
      //     </>
      //   );
      case "Story Burndown":
        return (
          <>
            <Box display={"flex"} justifyContent={"end"} className="mx-4">
              <div
                className="border text-white px-4 py-2 mx-1 text-center rounded"
                style={{ backgroundColor: "#5EBDCC" }}
              >
                <div>Work Remaining</div>
                <h5 className="mb-0">{storyBurndownValues?.RemainingWork}</h5>
                <div>Task</div>
              </div>
              <div
                className="border text-white px-4 py-2 mx-1 text-center rounded"
                style={{ backgroundColor: "#5EBDCC" }}
              >
                <div>Est Velocity</div>
                <h5 className="mb-0">{storyBurndownValues?.SprintVelocity}</h5>
                <div>Task Per Week</div>
              </div>
              <div
                className="border text-white px-4 py-2 mx-1 text-center rounded"
                style={{ backgroundColor: "#5EBDCC" }}
              >
                <div>For Cast</div>
                <h5 className="mb-0" style={{ color: "green" }}>
                  {storyBurndownValues?.EstimatedCompletionDate &&
                    format(
                      new Date(storyBurndownValues?.EstimatedCompletionDate),
                      "MMM dd,yyyy"
                    )}
                </h5>
                <div>Estimate Completion</div>
              </div>
            </Box>
            <MultipleCompareBar
              graphData={data}
              graphId={"checkBar"}
              yAxesLabel={yAxesLabelText}
            />
          </>
        );
      case "Sonar Qube":
        return (
          <MultipleCompareBar
            graphData={data}
            graphId={"checkBar"}
            yAxesLabel={yAxesLabelText}
          />
        );

      //   case "Test Coverage":
      //   case "Project Defect Injection":
      //   case "Sprint Execution Efficiency":
      //   case "Defect injection":
      //   case "Velocity Vs Commitment":
      //     return (
      //       <>
      //         <MultiBarChart
      //           graphData={TrendData}
      //           graphId={"testBar"}
      //           yAxesLabel={yAxesLabelText}
      //         />
      //       </>
      //     );
      //   case "Scope Changes":
      //     return (
      //       <>
      //         <StackedColumnChart graphData={data} yAxesLabel={yAxesLabelText} />
      //       </>
      //     );
      default:
        return <p>Invalid metric selected</p>; // replace with actual issue type name
    }
  };

  return (
    <div ref={trendDiv}>
      <Card className="customCard" style={{ marginTop: "30px", width: "100%" }}>
        <h4 className="px-4 pt-2">{metricName}</h4>
        {getTrends(metricName, data)}
      </Card>
    </div>
  );
};

export default RelevanceLabSprintTrend;
